<template>
  <div class="home">
    <div class="bg--main">
      <div class="container container--header">
        <div class="header">
          <router-link to="/" class="header__logo" />
          <div class="header-block">
            <p class="header-block__name">
              Добрый день!
            </p>
            <img src="@/assets/icons/name_icon.png"
              srcset="@/assets/icons/name_icon@2x.png 2x" alt="" class="header-block__icon"
            >
          </div>
        </div>
      </div>
      <div class="container">
        <div class="meditation">
          <h1 class="meditation__tit">
            Готовы начать медитацию?
          </h1>
          <div class="meditation-blocks">
              <a
                href="#" class="meditation-block"
                v-for="(song, index) in songs"
                :key="song.id"
                @click.prevent="selectTrack(song, index)"
              >
                <div class="meditation-block__img-block">
                    <img :src="song.photo_url['2x'].replace('.fit', '.ru')" alt="img" class="meditation-block__img">
                </div>
                <div class="meditation-block__info">
                  <div class="meditation-block__info-play"></div>
                  <div class="meditation-block__info-name">
                    {{ song.name }}
                  </div>
                </div>
              </a>
          </div>
        </div>
      </div>
  </div>
  <div class="player" v-if="song" @click.prevent="song = null">
    <div class="player-block"
      @click.stop
      :style="'background-image: url(' + bg + ')'"
    >
        <div class="player-block__name">
          {{ song.name }}
        </div>
        <audio
          :src="song.link.replace('.fit', '.ru')"
          controls @loadedmetadata="metaLoaded" ref="pl"
          @timeupdate="timeUpdate"
          @ended="trackEnd"
        />
        <div class="player-block__controller">
            <div class="player-block__info">
              <div class="player-block__info-times">
                <div class="player-block__info-time">
                  {{current}}
                </div>
                <div class="player-block__info-time player-block__info-time--all">
                  {{duration}}
                </div>
              </div>
              <div class="player-block__info-line">
                <div class="player-block__info-line--2" :style="{width: pos + '%'}"/>
                <input type="range" :value="pos" max="100" step="0.01" @change="changePos"/>
              </div>
            </div>
            <div class="player-block__buttons">
              <button class="player-block__button"
                :class="{'player-block__button--active' : isOnRepeat}"
                @click="isOnRepeat = !isOnRepeat"/>
              <button class="player-block__button player-block__button-back"
                @click.prevent="playPrev"/>
              <button class="player-block__button player-block__button-play"
                @click.prevent="play" v-if="!playing" />
              <button
                class="player-block__button player-block__button-play
                player-block__button-play--pause"
                @click.prevent="pause" v-else />
              <button class="player-block__button player-block__button-forward"
                @click.prevent="playNext"/>
              <button class="player-block__button player-block__button-shuffle"
                :class="{'player-block__button--active' : isShuffle}"
                @click="isShuffle = !isShuffle"/>
            </div>
        </div>
        <button class="player-block__back" @click.prevent="song = null"></button>
        <button class="player-block__like"></button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      songs: [],
      song: null,
      duration: '00:00',
      current: '00:00',
      durationTime: 0,
      currentTime: 0,
      playing: false,
      isOnRepeat: false,
      isShuffle: false,
      currentTrack: null,
    };
  },
  components: {
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/meditation/songs', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.songs = response.data.result;
      }
    },
    selectTrack(song, index) {
      this.song = song;
      this.currentTrack = index;
      this.$nextTick(() => {
        this.play();
      });
    },
    metaLoaded() {
      this.duration = this.formatTime(this.$refs.pl.duration);
      this.durationTime = this.$refs.pl.duration;
    },
    timeUpdate() {
      if (!this.$refs.pl) {
        return;
      }
      this.current = this.formatTime(this.$refs.pl.currentTime);
      this.currentTime = this.$refs.pl.currentTime;
    },
    play() {
      this.$refs.pl.play();
      this.playing = true;
    },
    pause() {
      this.$refs.pl.pause();
      this.playing = false;
    },
    trackEnd() {
      if (this.isOnRepeat) {
        this.$refs.pl.currentTime = 0;
        this.$refs.pl.play();
      } else {
        this.playNext();
      }
    },
    getRandomTrack() {
      const track = Math.floor(Math.random() * this.songs.length);
      if (track !== this.currentTrack) {
        return track;
      }
      return this.getRandomTrack();
    },
    playNext() {
      let track = 0;
      if (this.isShuffle) {
        track = this.getRandomTrack();
      } else if (this.currentTrack < this.songs.length - 1) {
        track = this.currentTrack + 1;
      }
      this.selectTrack(this.songs[track], track);
    },
    playPrev() {
      let track = this.currentTrack - 1;
      if (this.currentTrack === 0) {
        track = this.songs.length - 1;
      }
      this.selectTrack(this.songs[track], track);
    },
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let sec = Math.floor(seconds - minutes * 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${minutes}:${sec}`;
    },
    changePos($evt) {
      this.$refs.pl.currentTime = (this.durationTime * $evt.target.value) / 100;
    },
  },
  computed: {
    bg() {
      if (!this.song) {
        return null;
      }
      if (document.body.clientWidth > 768) {
        return this.song.photo_url['2x'].replace('.fit', '.ru');
      }
      return this.song.mobile_photo_url['2x'].replace('.fit', '.ru');
    },
    pos() {
      if (!this.durationTime) {
        return 0;
      }
      return (this.currentTime / this.durationTime) * 100;
    },
  },
};
</script>
<style lang="scss">
audio {
  display: none;
}
.meditation-block {
  &__img-block {
    overflow: hidden;
    & img {
      transition: .3s ease transform;
    }
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
    & .meditation-block__info-play:after {
      transform: translate(-50%, -50%) scale(0);
    }
  }
  &__info-play {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      width: 8rem;
      height: 8rem;
      background: rgba(255,255,255,.2);
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .3s ease transform;
      @media (max-width: 768px) {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
.meditation-block__img {
  @media (max-width: 768px) {
    top: -2rem;
  }
}
.player {
  display: flex;
  &-block {
    height: 81.7rem;
    width: 83.4rem;
    box-sizing: border-box;
    max-height: 90vh;
    padding: 3.7rem 3.7rem 4.3rem 3.7rem;
    @media (max-width: 768px) {
      min-height: 100vh;
      width: 100%;
      padding: 1.8rem 1.8rem 7rem;
      &__name {
        padding-top: 15rem;
      }
    }
    &__like {
      display: none;
    }
  }
}
.player-block__back {
  top: 10rem;
}
.player-block__button {
  transition: .3s ease all;
  &--active,
  &:hover {
    transform: scale(1.05);
    filter: brightness(1.3);
  }
}
input[type=range] {
  -webkit-appearance: none;
  margin: 11px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff !important;
  -webkit-appearance: none;
  border-radius: 1rem;
  cursor: pointer;
  margin-top: -14px;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}

input[type=range]::-moz-range-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff;
  border-radius: 1rem;
  background: #ffffff;
  cursor: pointer;
  box-shadow: #fff;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}

input[type=range]::-ms-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff;
  border-radius: 1rem;
  background: #ffffff;
  cursor: pointer;
  box-shadow: #fff;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}
</style>
